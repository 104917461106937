.btn,
.navbar .navbar-nav > a.btn{
  i {
    margin-right: 3px;
  }
}
.table not(.btn-group) .btn + .btn,
.table .btn-group + .btn-group{
  margin-left: 4px;
}
.btn + .btn,
.btn-group + .btn-group {
  margin-left: 5px;
}

.threedots {
  height: 20px;
  background-image: radial-gradient(circle, black 1px, transparent 3px);
  background-size: 100% 33.33%;
  color: black
}
