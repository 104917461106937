.drop-btn {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 15px;
  color: #66615b;
  line-height: normal;
  padding: 10px;
  font-size: 14px;
  transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  box-shadow: none;
  cursor: pointer;
}

.drop {
  position: relative;
  display: inline-block;
}

.drop-content {
  padding-top: 10px;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  max-height: 180px;
  overflow: auto;
  border-radius: 15px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.drop-content label {
  cursor: pointer;
  color: black;
  padding: 0 10px 0;
  text-decoration: none;
  display: block;
}

.drop-content label input {
  cursor: pointer;
}

.drop-content a:hover {
  background-color: #f1f1f1;
}

.drop-content label:hover {
  background-color: #1f77d0;
  color: #ffffff;
}

// .drop:hover .drop-content {
//   display: block;
// }

.drop:hover .dropbtn {
}
