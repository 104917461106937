%card-stats {
  hr {
    margin: 5px 15px;
  }
}

.card-stats {
  .card-body {
    padding: 15px 15px 0px;

    .numbers {
      text-align: right;
      font-size: 2em;

      p {
        margin-bottom: 0;
      }
      .card-category {
        color: $dark-gray;
        font-size: 16px;
        line-height: 1.4em;
      }
    }
  }
  .card-footer {
    padding: 0px 15px 15px;

    .stats {
      color: $dark-gray;
    }

    hr {
      margin-top: 10px;
      margin-bottom: 15px;
    }
  }
  .icon-big {
    font-size: 3em;
    min-height: 64px;

    i {
      line-height: 59px;
    }
  }
}
.card-stats:hover {
  background-color: #85a4a8;
  color: antiquewhite;
}
